import React, {Component} from "react"
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button'
import ArrowRightIcon from '@material-ui/icons/ArrowForwardIosRounded'
import Layout from "../components/layout"
import SEO from "../components/seo"
import {Articles} from "../components/learning-library/articles"
import {AllTopics} from "../components/learning-library/all-topics"
import {SearchWrapper} from "../components/learning-library/search-wrapper"
import {SegmentAnalytics} from "../common/utils/SegmentAnalytics";
import {SegmentEvents} from "../common/utils/SegmentSpec";
import {isValidPhone} from "../common/utils/CommonUtils";
import {BranchUtils} from "../common/utils/BranchUtils";
import BreadCrumbs from "../components/bread-crumbs";
import "./learning-library.scss"

const breadCrumbsList  = [

    {
        title: "Home",
        link: "/",
    }

]

class LearningLibrary extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: false,
            contentLoaded: false,
            SearchedArticles: [],
            questions: [],
            searchLibraryValue: '',
            Searched: false,
            phone: "",
            success: false,
            loader: {
                options: {
                    position: 'absolute'
                }
            }
        }
    }

    componentDidMount() {
        window.analytics.page("LearningLibrary");
        this.setState({
            contentLoaded: false,
        })
    }

    sendPhone(phoneNumber) {
        if (!this.state.error) {
            const phone = isValidPhone(phoneNumber);
            if (phone) {
                SegmentAnalytics.track(
                    SegmentEvents.USER_REQUESTED_FOR_APP_FROM_LEARNING_LIBRARY_PAGE,
                    {
                        phone,
                        appRequested: true,
                    });

                let fromPage = "Learning-Library";
                BranchUtils.desktopSendSMS(phone, fromPage, 'Bottom CTA', fromPage);

                this.setState({
                    success: true,
                })
            } else {
                console.log("this.state.error", this.state.error);
                this.setState({
                    error: true,
                    success: false,
                    phone: "",
                })
            }
        }
    };

    render() {
        return (
            <Layout location={this.props.location}>
                <SEO title="Learning Library"/>
                <BreadCrumbs currentPage={"Learning Library"} breadCrumbsList={breadCrumbsList}/>
                <div className="learning-lib-head">
                    <h2 className="blackHeading">Learning Library</h2>
                </div>
                <SearchWrapper/>
                <div className="lib-article-wrapper">
                    <h2 className="heading2">Popular Articles</h2>
                    <Articles/>
                </div>

                <AllTopics/>

                <div className="start-today-wrapper">
                    <div className="start-side">
                        <div className="start-inner">
                            <h2 className="start-main-text">Get Started
                                Today:</h2>
                            <a className="share-link"
                               href="https://confidanthealth.typeform.com/to/e04mW6"
                               target="_blank" rel="noopener noreferrer">
                                <div className="start-list">
                                    <div className="single-start">
                                        <p>Behavioral Health</p>
                                        <ArrowRightIcon/>
                                    </div>
                                    <div className="single-start">
                                        <p>Naloxone</p>
                                        <ArrowRightIcon/>
                                    </div>
                                    <div className="single-start">
                                        <p>Suboxone</p>
                                        <ArrowRightIcon/>
                                    </div>
                                    <div className="single-start">
                                        <p>Family Dynamics</p>
                                        <ArrowRightIcon/>
                                    </div>
                                    <div className="single-start">
                                        <p>Grief</p>
                                        <ArrowRightIcon/>
                                    </div>
                                    <div className="single-start">
                                        <p>Depression</p>
                                        <ArrowRightIcon/>
                                    </div>
                                </div>
                            </a>
                            <p className="start-now-text">We’re optimized for
                                appointments
                                within 24-48 hours.</p>
                        </div>
                    </div>
                    <div className="pro-app-wrapper">
                        <div className="pro-inner">
                            <p className="pro-header">Providers are on the app after work hours too</p>
                            <div className="input-wrap">
                                <TextField
                                    id="phone-input"
                                    className="phone-input"
                                    placeholder="Enter phone number"
                                    value={this.state.phone}
                                    margin="normal"
                                    onChange={event => {
                                        const {value} = event.target;
                                        this.setState({
                                            phone: value,
                                            error: false,
                                            success: false,
                                        })
                                    }}
                                />
                                <Button className="blue-simple-btn"
                                        color="primary"
                                        onClick={() => {
                                            this.sendPhone(this.state.phone)
                                        }}>
                                    Get App
                                </Button>
                            </div>
                            {this.state.error ? <p
                                className="lib-error-msg">Please Enter Valid
                                Phone</p> : null}
                            {this.state.success ? <p
                                className="lib-success-msg">Got it! Sending you a text with the link</p> : null}
                            <div className="store-btns">
                                <Button className="itune-btn" color="primary">
                                    <a target="_blank" rel="noopener noreferrer"
                                       href="https://apps.apple.com/us/app/confidant-health/id1478727129">
                                        <img src={require(
                                            '../assets/images/itunes.svg')}
                                             alt="itunebtn"/>
                                    </a>
                                </Button>
                                <Button className="playstore-btn"
                                        color="primary">
                                    <a target="_blank" rel="noopener noreferrer"
                                       href="https://play.google.com/store/apps/details?id=live.safeopioidusecoalition.confidant&hl=en">
                                        <img src={require(
                                            '../assets/images/playstore.png')}
                                             alt="playstorebtn"/>
                                    </a>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }

}

export default LearningLibrary

