import React from "react"
import ClockIcon from '@material-ui/icons/Schedule'
import {graphql, Link, StaticQuery} from "gatsby"
import {getProcessedArticles} from "../../common/utils/CommonUtils"
import '../../styles.scss'
import '../../pages/learning-library.scss'
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image"
var Loader = require('react-loader');
export const Articles = () => {
    return (
        <StaticQuery
            query={graphql`
            query PopularEducational {
                allContentfulCategory {
                    nodes {
                        name
                        slug
                        id
                        topics {
                            id
                            name
                            slug
                            publishOnWebsite
                            educationOrder {
                                id
                                slug
                                authorName
                                contentLengthduration
                                publishOnWebsite
                                description
                                isPopular
                                authorImage {
                                    file {
                                        fileName
                                        url
                                    }
                                    gatsbyImageData(layout: FULL_WIDTH)
                                }
                                authorOrganization
                                titleImage {
                                    file {
                                        fileName
                                        url
                                    }
                                    gatsbyImageData(layout: FULL_WIDTH)
                                }
                                title {
                                    title
                                }
                            }
                        }
                    }
                }
              }  
      `}
            render={data => {
                const popularArticlesData = getProcessedArticles(
                    data.allContentfulCategory).filter(
                    item => item.isPopular === true).slice(0, 3);

                return (
                    <div className="new-home-popular-article-list">
                        {popularArticlesData ?
                            popularArticlesData.length > 0 ?
                                popularArticlesData.map((item) => {
                                    return (
                                        <Link
                                            to={`/${item.categorySlug}/${item.topicSlug}/${item.slug}/`}
                                            className="non-underlined-link">
                                            <div
                                                className="new-home-popular-article">
                                                <div className="new-article-img">
                                                    {
                                                        item.titleImage !== null ?
                                                            <GatsbyImage
                                                                image={getImage(item.titleImage)}
                                                                className="new-article-image"
                                                                // src={`${item.titleImage.file.url}`}
                                                                alt="Topic Icon"/>
                                                            :
                                                            <StaticImage
                                                                className="new-article-image"
                                                                src={'../../assets/images/article-img2.png'}
                                                                alt="Logo"/>
                                                    }
                                                </div>
                                                <div
                                                    className="new-article-content">
                                                    <h2 className="main-heading">{item.title.title}</h2>
                                                    <p className="new-article-para">{item.description}</p>
                                                </div>
                                                <div className="new-article-author-content">
                                                    <div className="grad-ring">
                                                        {
                                                            item.authorImage !== null ?
                                                                <GatsbyImage
                                                                    image={getImage(item.authorImage)}
                                                                    className="new-article-author"
                                                                    // src={`${item.authorImage.file.url}`}
                                                                    alt="Author"/>

                                                                :
                                                                <StaticImage
                                                                    className="new-article-author"
                                                                    src={'../../assets/images/p4.png'}
                                                                    alt="Author"/>
                                                        }
                                                    </div>
                                                    <div className="author-info">
                                                        <p className="name-text">{item.authorName}</p>
                                                        <p className="role-text">Therapist & Author</p>
                                                    </div>
                                                    <div className="new-article-time">
                                                        <div className="clock-ring">
                                                            <ClockIcon/>
                                                        </div>
                                                        <p className="time-text">{item.contentLengthduration}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    )
                                }) : <p>No Articles Found </p> : <Loader
                                loaded={false}/>
                        }
                    </div>
                )
            }}
        />
    )
};
