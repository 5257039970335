/**
 * Created by Ishaq on 3/2/2019.
 */
import React from "react"

import {graphql, Link, StaticQuery} from "gatsby"

var Loader = require('react-loader');
export const AllTopics = () => {
    return (

        <StaticQuery
            query={graphql`
            query {
                allContentfulCategory {
                    nodes {
                      name
                      slug
                      topics {
                        publishOnWebsite
                        name
                        slug
                        icon {
                            file {
                              url
                            }
                          }
                      }
                    }
                  }
              }
      `}
            render={data => {
                data = data.allContentfulCategory.nodes.map((item) => {

                    if (item.topics) {
                        item.topics = item.topics.filter((topic) => {
                            return !!topic && topic.publishOnWebsite === true;
                        }).slice(0, 6);
                    }
                    return item;

                });
                return (
                    <div className="categories-wrapper">
                        <div className="category-list">
                            {data ?
                                data.length > 0 ?
                                    data.map((category) => {
                                        return (
                                            <div className="lib-topic-wrapper">
                                                <div className="topic-inner">
                                                    <h2 className="heading2">{category.name}</h2>

                                                    <div className="topic-list">
                                                        {category.topics ?
                                                            category.topics.length > 0 ?
                                                                category.topics.map(
                                                                    (items, index) => {
                                                                        return (
                                                                            <div className="single-topic" key={index}>
                                                                                <div className="gre-area">
                                                                                    {items.icon !== null ?
                                                                                        <img
                                                                                            className="topic-icon"
                                                                                            src={`${items.icon.file.url}`}
                                                                                            alt="Topic Icon"/>
                                                                                        :
                                                                                        <img
                                                                                            className="topic-icon"
                                                                                            src={require('../../assets/images/med_icon.png')}
                                                                                            alt="Topic Icon"/>
                                                                                    }
                                                                                </div>
                                                                                <div className="gre-content">
                                                                                    <h4 className="heading4">{items.name}</h4>
                                                                                    <p className="topic-count">{items.length}</p>
                                                                                    <Link
                                                                                        className="topic-click"
                                                                                        to={`/${category.slug}/${items.slug}/`}/>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    }) :
                                                                <p>No
                                                                    Question
                                                                    Found </p>
                                                            : <Loader
                                                                loaded={false}
                                                                options={this.state.loader.options}/>
                                                        }
                                                    </div>
                                                    <Link
                                                        className="blue-link"
                                                        to={`/${category.slug}/`}>All
                                                        Topics
                                                        in {category.name}  </Link>
                                                </div>
                                            </div>
                                        )
                                    }) : <p>No Record Found </p> : <Loader
                                    loaded={false}
                                    options={this.state.loader.options}/>
                            }
                        </div>
                    </div>
                )
            }}
        />

    )
};
